<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="所属阶梯" prop="type">
        <a-input v-model="form.type" type="number" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="是否无穷大" prop="typeValue">
        <a-radio-group v-model="form.typeValue">
          <a-radio-button :value="0">否</a-radio-button>
          <a-radio-button :value="1">是</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="用水量起始值" prop="startVale">
        <a-input v-model="form.startVale" type="number" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="用水量结束值" prop="endVale" v-if="form.typeValue == 0">
        <a-input v-model="form.endVale" type="number" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="金额(圆/吨)" prop="waterMoney">
        <a-input v-model="form.waterMoney" type="number" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入"/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { deviceOrdinateTypeInfo, deviceOrdinateTypeAdd, deviceOrdinateTypeEdit } from '@/api/pages/device'
const validateNum = (rule, value, callback) => {
  // console.log(rule, value)
  // if (!/^(?:[0-9]\d*)$/.test(value)) {
  //   callback(new Error('请输入正整数'))
  // } else {
  //   callback()
  // }
  if (!/^[1-9][0-9]*([\.][0-9]{1,2})?$/.test(value)) {
    callback(new Error('请输入正确数字（最多两位小数）'))
  } else {
    callback()
  }
}
export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单
      form: {
        id: 0,
        deviceOrdinateId: '',
        endVale: '',
        name: '',
        remark: '',
        startVale: '',
        type: '',
        waterMoney: '',
        typeValue: 0
      },
      open: false,
      rules: {
        name: [{ required: true, message: '配价方案名称不能为空', trigger: 'blur' }],
        deviceOrdinateId: [{ required: true, message: '请选择配价方案', trigger: 'blur' }],
        startVale: [{ required: true, message: '用水量起始值不能为空', trigger: 'blur' }],
        endVale: [{ required: true, message: '用水量结束值不能为空', trigger: 'blur' }],
        type: [{ required: true, message: '所属阶梯不能为空', trigger: 'blur' }],
        waterMoney: [{ required: true, message: '金额不能为空', trigger: 'blur' },
          { validator: validateNum, trigger: ['blur', 'change'] }
        ],
        typeValue: [{ required: true, message: '请选择是否无穷大', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
    form: {
      handler(newVal) {
        // console.log(newVal.typeValue)
        if (newVal.typeValue === 1) {
          this.form.endVale = 0
        }
      },
      deep: true
    }
  },
  methods: {
    change(value, dateString) {
      console.log(value, dateString)
      this.form.publishTime = dateString
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: 0,
        deviceOrdinateId: '',
        endVale: '',
        name: '',
        remark: '',
        startVale: '',
        type: '',
        waterMoney: '',
        typeValue: 0
      }
    },
     /** 新增按钮操作 */
    handleAdd (id) {
      console.log(id)
      const that = this
      this.reset()
      setTimeout(() => {
        that.form.deviceOrdinateId = id || that.form.deviceOrdinateId
      }, 500)
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      deviceOrdinateTypeInfo({ id: configId }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            deviceOrdinateTypeEdit(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            deviceOrdinateTypeAdd(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
